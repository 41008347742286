<template>
    <div class="wrapper">
        <router-view/>
    </div>
</template>

<script>
export default {
  name: 'AppSkeleton'
}
</script>
