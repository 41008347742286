<template>
    <div>
        <landing-page-header v-on:scrollTo="goToSection('placeOrder')"></landing-page-header>
        <main>
            <div class="hero-carousel-section">
                <div class="hero-carousel-overlay"></div>
                <div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <img src="../assets/home/slider1.jpg" class="d-block w-100" alt="Order completed">
                            <div class="hero-carousel-caption">
                                <h1>On Time Delivery</h1>
                                <h3>Experience a hassle-free delivery</h3>
                                <div class="hero-button">
                                    <router-link class="btn btn-orange pr-5 pl-5 pt-3 pb-3" to="/register">Get Started</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img src="../assets/home/slider2.jpg" class="d-block w-100" alt="Rider fleet">
                            <div class="hero-carousel-caption">
                                <h1>International Delivery</h1>
                                <h3>We can deliver your items within and outside Nigeria</h3>
                                <div class="hero-button">
                                    <router-link class="btn btn-orange pr-5 pl-5 pt-3 pb-3" to="/register">Get Started</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="carousel-item">
                            <img src="../assets/home/slider3.jpg" class="d-block w-100" alt="Rider in motion">
                            <div class="hero-carousel-caption">
                                <h1>Fast And Safe Delivery</h1>
                                <h3>Our riders are one tap away from you</h3>
                                <div class="hero-button">
                                    <router-link class="btn btn-orange pr-5 pl-5 pt-3 pb-3" to="/register">Get Started</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a class="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
            </div>
            
            <!-- <section class="hero-area">
                <header class="hero row">
                    <div class="hero-text mr-lg-3 col-12 col-md-6">
                        <p>Reliable worldwide delivery service</p>
                        <h1 class="mt-3 mb-5">We deliver, and we do it on time</h1>
                        <div class="hero-button">
                            <router-link class="btn btn-orange btn-get-started pr-5 pl-5 pt-3 pb-3" to="/register">Get Started</router-link>
                        </div>
                    </div>
                    <div class="hero-banner ml-3 d-md-block col-md-5">
                        <img class="img-fluid" src="../assets/home/hero-mobile-plain.png" alt="FTD hero banner">
                    </div>
                </header>
            </section> -->
            <section class="services-area">
                <div class="row">
                    <div class="services-banner col-lg-5">
                        <img src="../assets/home/second-image.jpg" alt="rider on bike">
                    </div>
                    <div class="services-list col-lg-7">
                        <div class="services-text mb-5">
                            <h3>What we do</h3>
                        </div>
                        <div class="services-types">
                            <div class="services-row row">
                                <div class="services-item col-12 col-sm-6 mb-5">
                                    <div class="services-icon">
                                        <span class="services-icon-container"><i class="fa fa-motorcycle fa-fw"></i></span>
                                    </div>
                                    <div class="services-content">
                                        <h5>Doorstep pickup/Delivery</h5>
                                        <p>
                                            We pick up parcels on behalf of our clients from locations within Lagos and Abuja and deliver them to their specified recipients.
                                        </p>
                                    </div>
                                </div>
                                <div class="services-item col-12 col-sm-6 mb-5">
                                    <div class="services-icon">
                                        <span class="services-icon-container"><i class="fa fa-truck-pickup fa-fw"></i></span>
                                    </div>
                                    <div class="services-content">
                                        <h5>Track your order status</h5>
                                        <p>
                                            We provide our clients with a unique tracking number for parcels sent out to any place within Lagos. This tracking number enables you to monitor the status of your order at each poin in transit.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="services-row row">
                                <div class="services-item col-12 col-sm-6 mb-5">
                                    <div class="services-icon">
                                        <span class="services-icon-container">
                                            <i class="fa fa-file-invoice fa-fw"></i>
                                        </span>
                                    </div>
                                    <div class="services-content">
                                        <h5>Payment on delivery</h5>
                                        <p>
                                            We accept payment on behalf of clients for delivery jobs carried out within Lagos and Abuja. Payments are remitted to our clients via bank transfer within 48 hours.
                                        </p>
                                    </div>
                                </div>
                                <div class="services-item col-12 col-sm-6 mb-5">
                                    <div class="services-icon">
                                        <span class="services-icon-container">
                                            <i class="fa fa-warehouse fa-fw"></i>
                                        </span>
                                    </div>
                                    <div class="services-content">
                                        <h5>Storage facility</h5>
                                        <p>
                                            We provide our client on subscription packages with storage facility for their bulk shipment.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="place-order-area" ref="placeOrder">
                <div class="row">
                    <div class="place-order-body col-12 col-md-6">
                        <div class="place-order-title">
                            <h3>Estimate delivery cost</h3>
                            <p>Need something delivered? Find out how much it will cost</p>
                        </div>
                        <div class="place-order-form">
                            <form @submit.prevent="getEstimate">
                                <div class="form-group">
                                    <label for="Pickup">From</label>
                                    <!-- <input type="text" name="pickup" id="pickup-address" class="form-control" placeholder="Pickup address" @keyup="findAddress($event.target, 'pickupLocation')" v-model="pickupLocation.full_address">
                                    <div class="help-text text-danger font-weight-bold" v-if="pickupLocation.address_not_found === true">
                                        Your pickup location could not be found. Please try another location.
                                    </div> -->
                                    <div class="form-row">
                                        <div class="form-group col-12 col-sm-6">
                                            <label for="State">State</label>
                                            <multiselect value="id" v-model="pickupLocation.state" :options="pickupStatesList" track-by="id" label="name" @select="(selected, id) => {pickupLocation.state_id = selected.id; getPickupCities(selected, id)}" select-label="" deselect-label=""></multiselect>
                                        </div>
                                        <div class="form-group col-12 col-sm-6">
                                            <label for="City">City</label>
                                            <multiselect value="id" v-model="pickupLocation.lga" :options="pickupCitiesList" track-by="id" label="name" @select="(selected, id) => {pickupLocation.lga_id = selected.id}" select-label="" deselect-label=""></multiselect>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="Delivery">To</label>
                                    <!-- <input type="text" name="delivery-address" id="delivery-address" class="form-control" @keyup="findAddress($event.target, 'deliveryLocation')" placeholder="Delivery address">
                                    <div class="help-text text-danger font-weight-bold" v-if="deliveryLocation.address_not_found === true">
                                        Your delivery location could not be found. Please try another location.
                                    </div> -->
                                    <div class="form-row">
                                        <div class="col-12 col-sm-4 form-group">
                                            <label for="country">Country</label>
                                            <multiselect :options="countriesList" v-model="deliveryLocation.country" track-by="id" label="name" @select="(selected, id) => {deliveryLocation.country_id = selected.id; getDeliveryStates(selected, id, index)}" select-label="" deselect-label=""></multiselect>
                                        </div>
                                        <div class="col-12 col-sm-4 form-group">
                                            <label for="State">State</label>
                                            <multiselect :options="deliveryLocation.deliveryStatesList" v-model="deliveryLocation.state" track-by="id" label="name" @select="(selected, id) => {deliveryLocation.state_id = selected.id; getDeliveryCities(selected, id, index)}" select-label="" deselect-label=""></multiselect>
                                        </div>
                                        <div class="col-12 col-sm-4">
                                            <label for="State">City</label>
                                            <multiselect :options="deliveryLocation.deliveryCitiesList" v-model="deliveryLocation.lga" track-by="id" label="name" @select="(selected, id) => {deliveryLocation.lga_id = selected.id}" select-label="" deselect-label=""></multiselect>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="Item weight">Weight of the item</label>
                                    <select name="itemWeight" id="itemWeight" class="form-control" v-model="itemWeight">
                                        <option value="">Select item weight</option>
                                        <option :value="w.id" v-for="w in weights" :key="w.id">{{ w.weight }}kg</option>
                                    </select>
                                </div>
                                <div class="card">
                                    <div class="card-footer">
                                        <button class="btn btn-outline-warning" type="submit" :disabled="searching">Get estimate</button>
                                        <!-- <button class="float-right btn btn-orange" type="button" @click="placeOrder" v-if="estimateAvailable == true && estimateFound == true">Place order</button> -->
                                    </div>
                                    
                                </div>
                            </form>
                        </div>
                        
                        <div class="place-order-estimate h2">
                            <span v-if="estimateMessage == 'no price'">To Be Calculated</span>
                            <span v-else>{{ formatNumberToCurrency(totalCost) }}</span>
                        </div>
                    </div>
                    <div class="place-order-banner col-12 col-md-6 d-none d-md-block">
                        <img src="../assets/home/third-image.jpg" class="img-fluid" alt="FTD estimate delivery cost">
                    </div>
                </div>
                
            </section>
            <section class="testimonials-area text-center">
                <h3 class="text-center mb-5">What our customers say</h3>
                <div class="testimonials-body ">
                    <div class="testimonials-carousel owl-carousel owl-theme">
                        <div class="testimonials-item">
                            <div class="bg-white rounded shadow">
                                <h5 class="text-center"><i class="fa fa-quote-left"></i></h5>
                                <p class="author">
                                    GSE (@glass_skin_effect)
                                </p>
                                <p>
                                    We use the best delivery service company Fast Track Delivery for all our orders both in and out of Nigeria.
                                </p>
                                
                            </div>
                        </div>
                        <div class="testimonials-item">
                            <div class="bg-white rounded shadow">
                                <h5 class="text-center"><i class="fa fa-quote-left"></i></h5>
                                <p class="author">
                                    LUSHSTONES (@lush_stones)
                                </p>
                                <p>
                                    I think your services are very commendable, no issues what so ever.
                                </p>
                                
                            </div>
                        </div>
                        <div class="testimonials-item">
                            <div class="bg-white rounded shadow">
                                <h5 class="text-center"><i class="fa fa-quote-left"></i></h5>
                                <p class="author">
                                    @estomi_
                                </p>
                                <p>
                                    Fast Track Delivery is awesome, they have never disappointed me, if your request isn’t possible. They let you know instead of leaving you hanging.
                                </p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="faqs-area"></section>
        </main>
        
        <landing-page-footer></landing-page-footer>
    </div>
</template>
<script>
import appconfig from '../appconfig';
import Auth from '../helpers/auth';
import instance from '../presenter/api/network';
import { loadScripts } from '../utils';
import Footer from './pages/layout/footer.vue';
import header from './pages/layout/header.vue';
import Multiselect from '@suadelabs/vue3-multiselect'
import '@suadelabs/vue3-multiselect/dist/vue3-multiselect.css'

export default {
  components: { LandingPageHeader: header, LandingPageFooter: Footer, Multiselect },
    beforeUnmount(){
        // window.removeEventListener("scroll", this.toggleStickyNavbar);
    },
    mounted(){
        this.getCountries().then(() => this.getPickupStates()).catch((err) => console.log(err));
        loadScripts(["assets/owl/owl.carousel.min.js"])
        // if (!window.google){
        //     loadScripts([
        //         `https://maps.googleapis.com/maps/api/js?key=${appconfig.googleMapsApiKey}&libraries=places`
        //     ])
        // }
        this.getWeights();
        // window.addEventListener("scroll", () => {
        //     this.toggleStickyNavbar();
        // })
        // document.querySelector('.toggle-mobile-menu').addEventListener("click", () => {
        //     this.openMobileMenu()
        // })
        // document.querySelector(".close-mobile-menu-button").addEventListener("click", () => {
        //     this.closeMobileMenu()
        // })
        $(document).ready(function(){
            setTimeout(() => {
                $('.owl-carousel').owlCarousel({
                    // slidesToShow: 3,
                    slidesToScroll: 1,
                    // rtl:true,
                    // loop: true,
                    margin:15,
                    nav:true,
                    // center: true,
                    responsive:{
                        0 : {
                            items: 1
                        },
                        768 : {
                            items: 2
                        },
                        992 : {
                            items: 2
                        },
                        1200:{
                            items: 3
                        }
                    }
            })    
            }, 2000);
            
        })
        
    },
    data(){
        return {
            appconfig: appconfig,
            pickupLocation: {
                full_address: "",
                country: "",
                state: "",
                lga: "",
                street: "",
                country_id: "",
                state_id: "",
                lga_id: "",
                address_not_found: false,
            },
            deliveryLocation: {
                full_address: "",
                country: "",
                state: "",
                lga: "",
                street: "",
                country_id: "",
                state_id: "",
                lga_id: "",
                google_address_selected: false,
                address_not_found: false,
                deliveryStatesList: [],
                deliveryCitiesList: []
            },
            weights: [],
            itemWeight: "",
            estimateAvailable: "", //request completed and both pickup and delivery location at least exists
            estimateFound: false, //request completed but no price set for the chosen locations
            estimateMessage: "",
            totalCost: 0,
            searching: false,
            countriesList: [],
            defaultCountry: null,
            pickupStatesList: [],
            pickupCitiesList: [],
        }
    },
    methods: {
        goToSection(refName){
            let element = this.$refs[refName];
            element.scrollIntoView({behaviour:'smooth'});
        },
        getWeights(){
            instance.get("get-weights").then(res => {
                this.weights = res.data.data;
            })
        },
        getCountries(){
            return instance.get("getCountryData").then(res => {
                    this.countriesList = res.data.data;
                    let countryNigeria = this.countriesList.find(item => {
                        return item.name.toLowerCase() == "nigeria";
                    });
                    this.defaultCountry = this.pickupLocation.country = countryNigeria; // = this.deliveryTemplate.country = countryNigeria;
                    this.pickupLocation.country_id = countryNigeria.id;
                }).catch(err => {
                    console.log(err);
                })
        },
        getPickupStates(){
            
            instance.get(`getStateData?country_id=${this.defaultCountry.id}&for=pickup`).then(res => {
                this.pickupStatesList = res.data.data;
            })
        },
        getPickupCities(selectedOption, id){
            this.pickupLocation.lga = "";
            instance.get(`getCityData?state_id=${selectedOption.id}&for=pickup`).then(res => {
                this.pickupCitiesList = res.data.data;
            })
        },
        getDeliveryStates(selectedOption, id, index){
            this.deliveryLocation.state = "";
            this.deliveryLocation.lga = "";
            instance.get(`getStateData?country_id=${selectedOption.id}&for=delivery`).then(res => {
                this.deliveryLocation.deliveryStatesList = res.data.data;
            })
        },
        getDeliveryCities(selectedOption, id, index){
            this.deliveryLocation.lga = "";
            instance.get(`getCityData?state_id=${selectedOption.id}&for=delivery`).then(res => {
                this.deliveryLocation.deliveryCitiesList = res.data.data;
            })
        },
        getEstimate(){
            if (this.pickupLocation.lga_id == ""){
                this.pickupLocation.address_not_found = true;
                return
            }
            if (this.deliveryLocation.lga_id == ""){
                this.deliveryLocation.address_not_found = true;
                return
            }
            this.estimateMessage = "";
            this.$root.startRequestLoader();
            let pickup = this.pickupLocation;
            let delivery = this.deliveryLocation;
            
            let formData = {
                from: {country_id: pickup.country_id, state_id: pickup.state_id, city_id: pickup.lga_id},
                to: {country_id: delivery.country_id, state_id: delivery.state_id, city_id: delivery.lga_id}
            }
            formData.weight_id = this.itemWeight;
            instance.post(`getPriceEstimate`, formData).then(res => {
                
                let response = res.data.data;
                if (res.data.message == "no price"){
                    this.estimateFound = false
                    this.estimateMessage = "no price";
                }else{
                    this.estimateFound = true;
                    this.totalCost = res.data.data.price;
                }
                this.estimateAvailable = true;
            }).catch(err => {
                if (err.response){
                    useToast().error(err.response.data?.message ?? "There was an error getting estimates, please try again")
                }else{
                    useToast().error("An unknown error occured");
                }
                
            }).finally(() => {
                this.$root.endRequestLoader();
            })
        },
        placeOrder(){
            let orderPrePlacementDetails = {
                pickup: this.pickupLocation,
                delivery: this.deliveryLocation
            }

            localStorage.setItem('orderPrePlacementDetails', JSON.stringify(orderPrePlacementDetails));
            if (Auth.isLoggedIn()){
                this.$router.push("/create-order");
            }else{
                this.$router.push('/register');
            }
        }
    }
}
</script>
<style scoped>
@import url('../../public/assets/owl/owl.carousel.min.css');
@import url('../../public/assets/owl/owl.theme.default.min.css');
@import url("../assets/home/landing-style.css");

.hero-carousel-section{
    position: relative;
}

.hero-carousel-overlay{
    position: absolute;
    height: 100%;
    width: 100%;
    /* opacity: 0.5; */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    /* background: black; */
    background-color: rgba(0,0,0,0.5);
}
.hero-carousel-section .carousel-item img{
    /* opacity: 0.6; */
}

.hero-carousel-caption{
    position: absolute;
    top: 35%;
    left: 18%;
    z-index: 4;
}

.hero-carousel-caption h1{
    font-size: 3rem;
    color: white;
    font-family: Lato;
    font-weight: 700;
}

.hero-carousel-caption h3{
    color: white;
    font-family: Lato;
    font-weight: 500;
    font-size: 1.5rem;
}

.carousel-control-prev,.carousel-control-next{
    z-index: 4;
    color:white;
}
@media only screen and (max-width: 768px){
    .hero-carousel-section img{
        min-height: 400px;
        object-fit: cover;
    }
}
</style>