import axios from 'axios'
import appconfig from '../../appconfig';

import Auth from '../../helpers/auth';

const baseURL = appconfig.apiBaseUrl;

const headers = {
  Authorization: `Bearer ${Auth.getAuthToken()}`,
  'content-type': 'application/json'
}

const timeout = 60000

const instance = axios.create({
  baseURL: baseURL,
  headers: headers,
  timeout: timeout
})

export default instance
