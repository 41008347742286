import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './presenter/store'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const currencyMixin = {
    methods: {
        formatNumberToCurrency(figure) {
            if (isNaN(figure)) {
                return "0.0";
            }
            if (Number.isInteger(figure)){
                return "₦" + Number(figure).toLocaleString();
            }
            let numberTo2DecimalPlaces = parseFloat(Number(figure).toFixed(2));
            return "₦" + numberTo2DecimalPlaces.toLocaleString();
            
            return Number(figure).toLocaleString('en', { style: 'currency', currency: '&#8358;' })
        }
    }
}

const vueApp = createApp(App);
vueApp.mixin(currencyMixin);
vueApp.use(Toast);
vueApp.use(store).use(router).mount('#app')
