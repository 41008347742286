export default{
    appName: "FTDLogistics",
    appShortName: "FTD",
    googleMapsApiKey: "AIzaSyC5AjDJ8ETEQj_fKeLa15oNnRwJUSdlYUg",
    apiBaseUrl: process.env.VUE_APP_API_URL ?? "https://ftd-backend.herokuapp.com/api/v1/",
    paystackPublicKey: process.env.VUE_APP_PAYSTACK_KEY,
    appBaseUrl: function(){
        if (process.env.VUE_APP_BASE_URL == undefined || process.env.VUE_APP_BASE_URL == ""){
            if (window.location.hash == ""){
                return window.location.origin;
            }else{
                return window.location.origin + "/#/"
            }
            
        }else{
            return process.env.VUE_APP_BASE_URL
        }
    }
}