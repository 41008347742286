import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import { create } from '../assets/lib/plugins/editors/quill/quill';
import Auth from '../helpers/auth';

const Logout = {
  methods: {
    logout(){
      Auth.logout();
      window.location.hash = "/login";
    }
  },mounted(){
    this.logout();
  }
}
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/track-order',
    name: "TrackOrder",
    component: () => import('../views/pages/order-tracking')
  },
  {
    path: '/start-order-placement',
    name: "PlaceOrder",
    component: () => import('../views/pages/place-order')
  },
  {
    path: '/pages/subscriptions',
    name: "SubscriptionPlans",
    component: () => import('../views/pages/subscriptions')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "about" */ '../views/Auth/Register.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Auth/Login.vue')
  },
  {
    path: '/logout', 
    name: 'Logout',
    component: Logout
  },
  {
    path: '/reset-password/confirm-password-reset',
    name: 'ConfirmPasswordReset',
    component: () => import('../views/Auth/ConfirmPasswordReset'),
    props: true,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/dashboard/index')
  },
  {
    path: '/account',
    name: 'AccountSettings',
    component: () => import('../views/profile/settings.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('../views/orders'),
    meta: { requiresAuth: true }
  },
  {
    path: "/create-order",
    name: 'CreateOrder',
    component: () => import('../views/orders/create'),
    meta: {requiresAuth: true}
  },
  {
    path: "/bulk-order",
    name: 'CreateBulkOrder',
    component: () => import('../views/orders/create-bulk-order'),
    meta: {requiresAuth: true}
  },
  {
    path: "/orders/:id/view",
    name: "ViewOrder",
    component: () => import('../views/orders/show'),
    meta: { requiresAuth: true },
    props: true
  },
  {
    path: "/wallet",
    name: "ViewWallet",
    component: () => import('../views/wallet'),
    meta: { requiresAuth: true }
  },
  {
    path: "/packages",
    name: "ViewPackages",
    component: () => import('../views/packages'),
    meta: { requiresAuth: true }
  },
  {
    path: "/pages/terms-general",
    name: "GeneralTerms",
    component: () => import('../views/pages/static/terms-general')
  },
  {
    path: "/pages/terms-international",
    name: "InternationalTerms",
    component: () => import('../views/pages/static/terms-international')
  },
  {
    path: "/pages/terms-local",
    name: "LocalTerms",
    component: () => import('../views/pages/static/terms-local')
  },
  {
    path: "/pages/about",
    name: "AboutUs",
    component: () => import('../views/pages/static/about')
  },
  {
    path: "/pages/privacy",
    name: "PrivacyPolicy",
    component: () => import('../views/pages/static/privacy-policy')
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!Auth.isLoggedIn()) {
      localStorage.setItem("intended_url", to.path);
      next({
        path: "/login"
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
