<template>
  <div>
    <request-loader v-if="requestLoaderActivated"></request-loader>
    <Authview v-if="!authenticated" />
    <AppSkeleton v-else />
  </div>
</template>

<script>
import AppSkeleton from './views/AppSkeleton'
import Authview from './views/Authview.vue'
import RequestLoader from './components/RequestLoader'
const loaderMixin = {
    components: {RequestLoader},
    data(){
        return {
            requestLoaderActivated: false
        }
    },
    methods: {
        startRequestLoader(){
          this.requestLoaderActivated = true;
        },
        endRequestLoader(){
          this.requestLoaderActivated = false;
        }
    }
}

// const currencyMixin = {
//   methods: {
//     formatNumberToCurrency(figure) {
//       if (isNaN(figure)){
//           return "0.0";
//       }
//       return Number(figure).toLocaleString('en', { style: 'currency', currency: '₦' })
//     }
//   }
// }
export default {
  mixins: [loaderMixin],
  components: {
    AppSkeleton,
    Authview
  },
  computed: {
    authenticated () {
      return this.$store.state.user.userData != null
    }
  }
}
</script>

<style>
.error{
  color: red;
}
</style>
