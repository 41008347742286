<template>
    <div>
        <footer>
            <div class="row">
                <div class="col-md-3 mb-5">
                    <h5 class="mb-3">Company</h5>
                    <ul class="nav flex-column">
                        <li><router-link to="/pages/about">About</router-link></li>
                        <li><router-link to="/pages/privacy">Privacy Policy</router-link></li>
                        <li><router-link to="/pages/terms-general">Terms and Conditions</router-link></li>
                        <li>
                            <router-link to='/pages/terms-international'>International shipments</router-link>
                        </li>
                        <li>
                            <router-link to="/pages/terms-local">Local shipments</router-link>
                        </li>
                        <li>
                            <a href="https://blog.ftdlogistics.com" target="_blank">Blog</a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-3">
                    <h5 class="mb-3">Surulere</h5>
                    <p style="line-height: 1.5rem">
                        1, Bassie Ogamba Str, 
                        <br>Off Adeniran Ogunsanya, 
                        <br>
                        Surulere, Lagos State, 
                        <br>
                        Nigeria.
                    </p>
                </div>
                <div class="col-md-3">
                    <!-- <h5 class="mb-3">Port Harcourt</h5>
                    <p style="line-height: 1.5rem">
                        ...
                    </p>

                    <br> -->

                    <h5 class="mb-3">Lekki</h5>

                    <p style="line-height: 1.5rem">
                        Block 82,<br> 
                        Emma Abimbola Cole, <br>
                        Lekki Phase 1, Lagos, <br>
                        Nigeria
                    </p>
                </div>
                <div class="col-md-3 mb-5">
                    <h5 class="mb-3">Contact</h5>
                    <ul class="nav flex-column">
                        <li>
                            <a href="tel:+2347034662252">+2347034662252</a>
                        </li>
                        <li>
                            <a href="tel:+2347012195355">+2347012195355</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)">info[@]ftdlogistics.com</a>
                        </li>
                    </ul>
                    <div>
                        <ul class="nav" style="font-size: 1.3rem;">
                            <li class="nav-link" style="padding-left: 0">
                                <a href="https://www.facebook.com/ftdlogistics" target="_blank" rel="noopener"><i class="fab fa-facebook fa-fw"></i></a>
                            </li>
                            <li class="nav-link">
                                <a href="https://www.instagram.com/ftdlogistics" target="_blank" rel="noopener">
                            <i class="fab fa-instagram fa-fw"></i></a>
                            </li>
                            <li class="nav-link">
                                <a href="https://www.twitter.com/ftdlogistics" target="_blank" rel="noopener"><i class="fab fa-twitter fa-fw"></i></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>
<script>
export default {
    name: "LandingPageFooter"
}
</script>