export function loadScripts(url) {
    let urls = [];
    if (typeof url == "string") {
        urls.push(url);
    } else {
        urls = url.slice();
    }
    urls.forEach(urlString => {
        const script = document.createElement("script");
        script.src = urlString;
        // script.async = true;
        // script.defer = true;
        document.body.appendChild(script);
    });
}