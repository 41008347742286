<template>
    
    <header class="sticky-top">
        <div class="navigation-container">
            <nav class="navigation-body">
                <a  href="#" class="navbar-brand"><img class="logo" src="../../../assets/new-logo.png" alt="Logo"></a>
                <div class="navigation-menu mr-auto">
                    <ul class="">
                        <li :class="[$route.path=='/' ? 'active' : '']">
                            <a href="/">Home</a>
                        </li>
                        <li>
                            <!-- <a href="javascript:void(0)" @click="emitEventToParent">Place order</a> -->
                            <router-link v-if="auth.isLoggedIn()" to="/create-order">Place order</router-link>
                            <router-link v-else to="/start-order-placement">Place order</router-link>
                        </li>
                        <li :class="[$route.path=='/track-order' ? 'active' : '']">
                            <router-link to="/track-order">Track order</router-link>
                        </li>
                        <li>
                            <router-link v-if="auth.isLoggedIn()" to="/packages">Pricing</router-link>
                            <router-link v-else to="/pages/subscriptions">Pricing</router-link>
                        </li>
                        <template v-if="!auth.isLoggedIn()">
                            <li>
                                <router-link to="/login">Sign in</router-link>
                            </li>
                            <li>
                                <router-link to="/register">Register</router-link>
                            </li>
                        </template>
                        <template v-else>
                            <li>
                                <router-link to="/create-order">My Account</router-link>
                            </li>
                            <li>
                                <router-link to="/logout">Logout</router-link>
                            </li>
                        </template>
                        <li>
                            <a href="https://blog.ftdlogistics.com" target="_blank">Blog</a>
                        </li>
                    </ul>
                </div>
                <div class="toggle-mobile-menu text-dark float-right d-md-none p-3" style="font-size: 1.5rem">
                    <i class="fa fa-bars"></i>
                </div>
                <div class="close-mobile-menu-button d-md-none">
                    <i class="fa fa-times"></i>
                </div>
            </nav>

            
        </div>
    </header>
    
</template>
<script>
import Auth from '../../../helpers/auth';
export default {
    name: "LandingPageHeader",
    mounted(){
        this.closeMobileMenu()
        window.addEventListener("scroll", () => {
            this.toggleStickyNavbar();
        })
        document.querySelector('.toggle-mobile-menu').addEventListener("click", () => {
            this.openMobileMenu()
        })
        document.querySelector(".close-mobile-menu-button").addEventListener("click", () => {
            this.closeMobileMenu()
        })
    },
    data(){
        return {
            auth: Auth
        }
    },
    methods: {
        emitEventToParent() {
            this.$emit("scrollTo", "placeOrder");
            // var element = this.$el[refName];
            // console.log(this.$el);
            // console.log('element', element);
            // element.scrollIntoView();
        },
        toggleStickyNavbar(){
            let header = document.querySelector("header.sticky-top");
            let navcontainer = document.querySelector(".navigation-container");
            let sticky = navcontainer.offsetTop;
            
            if(window.pageYOffset > sticky){
                navcontainer.style = "background: white";
                navcontainer.classList.add("shadow", "sticky-header-in");
            }else{
                navcontainer.style = "initial";
                navcontainer.classList.remove("shadow", "sticky-header-in");
            }
        },
        openMobileMenu(){
            let menubar = document.querySelector(".navigation-menu");
            let closeButton = document.querySelector(".close-mobile-menu-button");
            closeButton.classList.add("show-close");
            menubar.classList.remove("mr-auto");
            menubar.classList.add("mobile-menu", "show-menu")
        },
        closeMobileMenu(){
            let menubar = document.querySelector(".navigation-menu");
            let closeButton = document.querySelector(".close-mobile-menu-button");
            closeButton.classList.remove("show-close");
            menubar.classList.add("mr-auto");
            menubar.classList.remove("mobile-menu", "show-menu")
        }
    }
}
</script>
<style scoped>
/* @import url("../../../assets/home/landing-style.css"); */
</style>