<template>
    <div>
        <!-- <div id="load_screen"> <div class="loader-body"> <div class="loader-content">
        <div class="spinner-grow align-self-center"></div>
        </div></div></div> -->

        <div class="loader-overlay" id="loader-overlay">
            <div class="loader-body">
                <div class="loader-content">
                    {{ loadingContent }}
                </div>
            </div>
            
        </div>
        <div class="loader"></div>
        
    </div>
</template>
<script>

export default {
    props: ["loadingContent"]
}
</script>

<style scoped>
@import url("../assets/lib/plugins/loaders/custom-loader.css");
div#loader-overlay {
    background: #ecefff;
    opacity: 0.5;
    position: fixed;
    z-index: 999;
    /* top: 0px;
    bottom: 0;
    left: 0;
    right: 0; */
    width: 100%; 
    height: 100vh;
}
div#loader-overlay .loader-body {
    display: flex;
    justify-content: center;
    height: 100vh; 
}
div#loader-overlay .loader-content {
    right: 0;
    align-self: center; 
}
div.loader{
    z-index: 1000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>