/* eslint-disable*/
const state = ()=> ({
    userData: window.localStorage.getItem("user_data"),
});

const getters = {

    retrieveUserToken(state, getters, rootState){
        let user_data = JSON.parse(window.localStorage.getItem("user_data"));
        return user_data && user_data.token ? user_data.token.access_token: null
    }
};

const mutations = {
    saveAuthenticatedUser(state, userData){
        window.localStorage.setItem("user_data", userData);
        state.userData = window.localStorage.getItem("user_data");
    }
};

const actions = {
    saveAuthenticatedUser({commit}, userData){
        commit('saveAuthenticatedUser', userData)
    }
};




export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}